import { Injectable, Optional, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { concatMap, Observable, of } from 'rxjs';
import { VendorInvoice, VendorInvoicePaymentStatus } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { VendorInvoiceStatsComponentService } from './vendor-invoice-stats.component.service';

@Injectable()
export class VendorInvoiceItemComponentService {
  memoFormControl: FormControl = new FormControl('');
  vendorInvoice!: VendorInvoice;

  isHover: WritableSignal<boolean> = signal(false);
  isHover$: Signal<boolean> = this.isHover.asReadonly();

  memoFormControlValueChanges$: Observable<boolean> = this.memoFormControl.valueChanges.pipe(
    concatMap((memo) => {
      if (this.vendorInvoice) {
        return this.vendorInvoiceStateService.updateVendorInvoice({
          ...this.vendorInvoice,
          memo,
        });
      }

      return of(false);
    }),
  );

  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    @Optional() private readonly vendorInvoiceStatsComponentService: VendorInvoiceStatsComponentService,
  ) {}

  populateMemoFormControl(memo: string): void {
    this.memoFormControl.setValue(memo, { emitEvent: false });
  }

  setIsHover(isHover: boolean): void {
    this.isHover.set(isHover);
  }

  async markVendorInvoiceAsPaid(vendorInvoice: VendorInvoice): Promise<void> {
    await this.vendorInvoiceStateService.updateVendorInvoice({
      ...vendorInvoice,
      paymentStatus: VendorInvoicePaymentStatus.PAID,
    });
    await this.vendorInvoiceStatsComponentService?.refreshStats();
  }
}
