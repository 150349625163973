import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  CurrencyClassPipe,
  DougsDatePipe,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  IconButtonComponent,
  MathAbsPipe,
  MathRoundPipe,
  MemoComponent,
  PillComponent,
  TrustRessourceUrlPipe,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { SalesInvoicePaymentStatus } from '@dougs/sales-invoice/dto';
import { VendorInvoice, VendorInvoicePaymentStatus, VendorInvoicePrefillStatus } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceActionsComponentService } from '../../../services/details/vendor-invoice-actions.component.service';
import { VendorInvoiceItemComponentService } from '../../../services/list/vendor-invoice-item.component.service';
import { VendorInvoiceOperationComponentService } from '../../../services/operation/vendor-invoice-operation.component.service';
import { getVendorInvoicePaymentStatus } from '../../../utils/vendor-invoice.utils';

@Component({
  selector: 'dougs-vendor-invoice-item, [dougs-vendor-invoice-item]',
  standalone: true,
  imports: [
    CommonModule,
    MemoComponent,
    ButtonComponent,
    FormsModule,
    PillComponent,
    ReactiveFormsModule,
    MathAbsPipe,
    DropdownComponent,
    DropdownOptionComponent,
    DropdownTriggerDirective,
    TrustRessourceUrlPipe,
    MathRoundPipe,
    CurrencyClassPipe,
    DougsDatePipe,
    IconButtonComponent,
  ],
  templateUrl: './vendor-invoice-item.component.html',
  styleUrls: ['./vendor-invoice-item.component.scss', '../../../scss/vendor-invoice-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VendorInvoiceItemComponentService, VendorInvoiceOperationComponentService],
})
export class VendorInvoiceItemComponent {
  @Output() vendorInvoiceAttached: EventEmitter<VendorInvoice> = new EventEmitter<VendorInvoice>();
  @Output() vendorInvoiceDetached: EventEmitter<VendorInvoice> = new EventEmitter<VendorInvoice>();
  @Input()
  @HostBinding('class.is-in-modal')
  isInModal = false;

  VENDOR_INVOICE_PREFILL_STATUS = VendorInvoicePrefillStatus;

  isPaid = false;
  vendorInvoiceStatus = '';

  private _operation!: Operation | null;

  get operation(): Operation | null {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation | null) {
    this._operation = operation;
    this.vendorInvoiceOperationComponentService.operationIsAttached(operation, this.vendorInvoice);
  }

  private _vendorInvoice!: VendorInvoice;

  @Input()
  set vendorInvoice(vendorInvoice: VendorInvoice) {
    this._vendorInvoice = vendorInvoice;
    this.vendorInvoiceItemComponentService.vendorInvoice = vendorInvoice;
    this.vendorInvoiceItemComponentService.populateMemoFormControl(vendorInvoice.memo);
    this.vendorInvoiceStatus = getVendorInvoicePaymentStatus(vendorInvoice);
    this.isPaid = this.vendorInvoice.paymentStatus === 'paid';
    this.vendorInvoiceOperationComponentService.operationIsAttached(this.operation, vendorInvoice);
  }

  get vendorInvoice(): VendorInvoice {
    return this._vendorInvoice;
  }

  constructor(
    public readonly vendorInvoiceItemComponentService: VendorInvoiceItemComponentService,
    public readonly vendorInvoiceOperationComponentService: VendorInvoiceOperationComponentService,
    public readonly companyServicesStateService: CompanyServicesStateService,
    public readonly vendorInvoiceActionsComponentService: VendorInvoiceActionsComponentService,
  ) {}

  attachTempOperation(): void {
    this.vendorInvoiceOperationComponentService.attachTempOperation();
    this.vendorInvoiceAttached.emit(this.vendorInvoice);
  }

  detachTempOperation(): void {
    this.vendorInvoiceOperationComponentService.detachTempOperation();
    this.vendorInvoiceDetached.emit(this.vendorInvoice);
  }

  protected readonly VendorInvoicePaymentStatus = VendorInvoicePaymentStatus;
  protected readonly SalesInvoicePaymentStatus = SalesInvoicePaymentStatus;
}
